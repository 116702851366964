import { css } from '@emotion/react'

export default css`
  pre {
    border-radius: 5px;
  }

  html {
    overflow-y: hidden;
  }

  @media screen and (max-width: 999px) {
    html,
    body {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 600px) {
    html {
      font-size: 18px;
    }

    body {
      font-size: 14px;
    }
  }
`
