import React from 'react'
import InfoHeader from './InfoHeader'
import styled from '@emotion/styled'
import AuthorSocialAccounts from './AuthorSocialAccounts'
import NavLinks from './NavLinks'

const SidebarStyles = styled.div`
  top: 0;
  left: 0;
  color: $(props => props.theme.colors.primary);
  width: 320px;
  height: 100%;
  display: block;
  padding: 20px 40px;
  position: absolute;

  &::after {
    top: 20px;
    right: 0;
    width: 1px;
    bottom: 20px;
    content: '';
    position: absolute;
    border-right: 1px solid ${props => props.theme.border.primary};
  }

  @media screen and (max-width: 999px) {
    position: relative;
    width: 100%;
    padding: 10px;

    &::after {
      left: 1%;
      top: 60px;
      bottom: 0;
      width: 98%;
      height: 1px;
      border-right: 0;
      border-bottom: 1px solid ${props => props.theme.border.primary};
    }
  }
`

const Sidebar = () => (
  <SidebarStyles>
    <InfoHeader />
    <AuthorSocialAccounts />
    <NavLinks />
  </SidebarStyles>
)

export default Sidebar
