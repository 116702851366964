module.exports = {
  siteTitle: 'Petros Kyriakou - Web journey one byte at a time', // <title>
  shortSiteTitle: 'Petros Kyriakou', // <title> ending for posts and pages
  siteDescription: 'Personal Blog is a GatsbyJS starter.',
  siteKeywords: 'javascript, tutorials, how to',
  siteUrl: 'https://www.petroskyriakou.com',
  pathPrefix: '',
  siteImage: 'preview.jpg',
  siteLanguage: 'en',
  // author
  authorName: 'Petros Kyriakou',
  authorTwitterAccount: 'yobrekcah',
  // info
  infoTitle: 'Petros Kyriakou',
  infoTitleNote: 'personal blog',
  infoBlogDescription: 'I am a full stack web developer. Love tinkering all the time, especially anything javascript related.',
  // manifest.json
  manifestName: 'PersonalBlog - a blog starter for GatsbyJS',
  manifestShortName: 'PersonalBlog', // max 12 characters
  manifestStartUrl: '/',
  manifestDisplay: 'standalone',
  // contact
  contactEmail: '9pitops@gmail.com',
  // social
  authorSocialLinks: [
    { name: 'github', url: 'https://github.com/pitops', icon: 'github' },
    { name: 'twitter', url: 'https://twitter.com/yobrekcah', icon: 'twitter' },
    {
      name: 'facebook',
      url: 'http://facebook.com/xamalis',
      icon: 'facebook-f'
    }
  ],
  facebook: {
    appId: process.env.FB_APP_ID ? process.env.FB_APP_ID : '572388319882801'
  }
}
