import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Sidebar from './sidebar/Sidebar'
import { css, Global, ThemeProvider } from '@emotion/react'
import styled from '@emotion/styled'
import globalStyles from '../styles/global'
import {theme} from '../styles/theme'

const styles = css`
  margin-left: 320px;

  @media screen and (max-width: 999px) {
    margin-left: 0;
  }
`

const LayoutStyles = styled.div`
  background-color: ${props => props.theme.bg.primary};
  color: ${props => props.theme.text.primary};

  a {
    color: ${props => props.theme.colors.primary};
    transition: color 0.2s;
    background-image: none;
    text-shadow: none;

    &:hover {
      color: ${props => props.theme.colors.secondary};
    }
  }
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={theme.dark}>
        <LayoutStyles>
          <Global styles={globalStyles} />
          <Sidebar />
          <div css={styles}>{children}</div>
        </LayoutStyles>
      </ThemeProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
