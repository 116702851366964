import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/react'

const styles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  a {
    font-weight: 300;
    font-size: 16px;
    margin: 5px 0;
  }

  @media screen and (max-width: 999px) {
    display: none;
  }
`

export default function NavLinks () {
  const links = [
    {
      name: 'about',
      path: '/about'
    },
    {
      name: 'contact',
      path: '/contact'
    }
  ]

  return (
    <div css={styles}>
      {links.map(link => {
        return (
          <Link to={link.path} key={link.path}>
            {link.name}
          </Link>
        )
      })}
    </div>
  )
}
