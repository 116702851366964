import React from 'react'

import { Link } from 'gatsby'
import styled from '@emotion/styled'
import * as config from '../../blog-config'

const InfoHeaderStyles = styled.div`
  text-align: center;
  margin-top: 10px;

  .avatar {
    width: 60px;
    height: 60px;
    margin: 0 auto 20px auto;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    /* border-radius: 31% 26% 45% 10% / 30% 30% 44% 0%; */
    border-radius: 50% 50% 50% 50% / 20% 20% 80% 80%;
    background-image: ${props => props.theme.logo.gradient},
      linear-gradient(45deg, #3023ae 0%, #ff0099 100%);

    &::after {
      content: '👨‍💻';
      font-size: 40px;
      position: absolute;
      bottom: -12px;
      right: -13px;
      transform: rotate(-53deg);
      transition: all 0.3s ease;
    }

    &:hover {
      border-radius: 31% 26% 0% 44% / 30% 30% 44% 45%;

      &::after {
        right: 10px;
        transform: rotate(0);
      }
    }

    img {
      height: 100%;
    }
  }

  .info {
    margin: 0;
    color: ${props => props.theme.colors.primary};
    font-size: 20px;

    small {
      display: block;
      font-weight: 300;
    }
  }

  .blog-description {
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
  }

  @media screen and (max-width: 999px) {
    text-align: left;
    display: flex;
    align-items: center;
    height: 40px;
    margin-top: 0;

    a {
      height: 40px;
    }

    .avatar {
      display: inline-block;
      width: 40px;
      height: 40px;
      margin: 0;

      img {
        margin: 0;
      }
    }

    .info {
      display: inline-block;
      margin-left: 10px;
      font-size: 16px;
    }

    .blog-description {
      display: none;
    }
  }
`

export default function InfoHeader() {
  return <InfoHeaderStyles>
    <Link to='/' title='back to Home page'>
      <div className='avatar' />
    </Link>
    <h3 className='info'>
      {config.infoTitle}
      <small>{config.infoTitleNote}</small>
    </h3>
    <p className='blog-description'>{config.infoBlogDescription}</p>
  </InfoHeaderStyles>
}
