export const theme = {
  dark: {
    colors: {
      primary: 'rgb(200, 197, 191)',
      secondary: 'rgb(188, 73, 255)'
    },
    bg: {
      primary: '#16171a'
    },
    text: {
      primary: 'rgba(232, 231, 227, 0.8)'
    },
    border: {
      primary: 'rgb(58, 58, 58)'
    },
    logo: {
      gradient:
        'linear-gradient(-225deg, rgb(23, 24, 28) 0%, rgba(23, 24, 28, 0) 40%, rgba(0, 204, 204, 0) 60%, rgb(0, 204, 204) 100%), linear-gradient(45deg, rgb(41, 30, 149) 0%, rgb(204, 0, 122) 100%)'
    },
    post: {
      header: 'rgb(210, 208, 203)'
    },
    pre: {
      background: 'rgb(38, 43, 52)'
    },
    scrollbar: {
      color: 'rgba(255,255,255,0.54)'
    }
  },
  light: {
    colors: {
      primary: '#555555',
      secondary: '#bc49ff'
    },
    bg: {
      primary: '#FFFFFF'
    },
    text: {
      primary: 'rgba(0,0,0,0.8)'
    },
    border: {
      primary: '#dedede'
    },
    logo: {
      gradient:
        'linear-gradient(-225deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 40%, rgba(0, 255, 255, 0) 60%, rgb(0, 255, 255) 100%), linear-gradient(45deg, rgb(48, 35, 174) 0%, rgb(255, 0, 153) 100%)'
    },
    post: {
      header: '#3a3939'
    },
    pre: {
      background: 'rgb(38, 43, 52)'
    },
    scrollbar: {
      color: '#dedede'
    }
  }
}
