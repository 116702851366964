import React from 'react'
import * as config from '../../blog-config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { css } from '@emotion/react'

const styles = css`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    background-image: none;
    padding: 5px;
    display: inline-block;
    text-align: center;
    width: 50px;
  }

  svg {
    width: 30px !important;
    height: 30px;
    color: #bbbbbb;
    transition: color 0.2s;
    display: inline-block;

    &:hover {
      color: #bc49ff;
    }
  }

  @media screen and (max-width: 999px) {
    display: none;
  }
`

export default function AuthorSocialAccounts () {
  const socialAccounts = config.authorSocialLinks

  return (
    <div css={styles}>
      {socialAccounts.map(acc => {
        return (
          <a
            href={acc.url}
            aria-label={acc.name}
            key={acc.name}
            target='_blank'
            rel='noopener noreferrer'
          >
            <FontAwesomeIcon icon={['fab', acc.icon]} />
          </a>
        )
      })}
    </div>
  )
}
